import React, { useState } from 'react';
import { HandySvg } from 'handy-svg';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Header.module.scss';
import main from '../../assets/vectorHeader/Main.svg';
import document from '../../assets/vectorHeader/Document.svg';
import arrowWhite from '../../assets/vectorHeader/ArrowWhite.svg';
import arrowGreen from '../../assets/vectorHeader/ArrowGreen.svg';

const Menu = ({ handleClick, name}) => {
    const onClassName = ({isActive}) => isActive
        ? classNames(styles.activeLink, styles.link)
        : styles.link;

    const [hover, setHover] = useState(false);

    const handleMouseOver = () => {
        setHover(true);
    };

    const handleMouseOut = () => {
        setHover(false);
    };

    return (
        <>
            <div className={styles.help}>
                <ul>
                    <li>
                        <HandySvg
                            src={main}
                            width='18'
                            height='18'
                        />
                        <NavLink to={'main/helpdesk'} className={onClassName}>Главная</NavLink>
                    </li>
                    <li>
                        <HandySvg
                            src={document}
                            width='15'
                            height='18'
                        />
                        <NavLink to={'https://dev.malankabn.by/help'} target="_blank" className={onClassName}>Помощь</NavLink>
                    </li>
                </ul>
            </div>
            <div className={styles.info}>
                <div className={styles.user}>
                    <NavLink to={'user'} className={onClassName}>{name}</NavLink>
                </div>
                <div>
                    <NavLink to={'/auth'}
                             className={styles.link}>
                        <button onClick={handleClick}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                        >
                            {
                                hover ?
                                    <HandySvg
                                        src={arrowGreen}
                                        width='17'
                                        height='16'
                                    />
                                    :
                                    <HandySvg
                                        src={arrowWhite}
                                        width='17'
                                        height='16'
                                    />
                            }
                        </button>
                    </NavLink>
                </div>
            </div>
        </>
    );
};

export default Menu;
