import React from 'react';

import style from './Input.module.scss';

const Input = ({defaultValue, width, height = 44, placeholder, onChange, id}) => {
    return (
        <input
            style={{width: `${width}px`, height: `${height}px`}}
            defaultValue={defaultValue}
            placeholder={placeholder}
            className={style.input}
            onChange={onChange}
            id={id}
        />
    );
};

export default Input;
