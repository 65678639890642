import React, { useContext, useEffect, useState } from 'react';
import styles from './Helpdesk.module.scss';

import SwiperHelpdesk from './SwiperHelpdesk';
import Button from '../../../components/Button/Button';
import { MyContext } from '../../../store';
import { postApi } from '../../../Api';

const HelpdeskOperator = ({data}) => {
    const [swiper, setSwiper] = useState(null);
    const [isStarted, setIsStarted] = useState(false);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [isShowMessage, setIsShowMessage] = useState(false);

    const {handleFormTicket} = useContext(MyContext);

    useEffect(() => {
        if (isShowMessage) {
            const timer = setTimeout(() => {
                setIsShowMessage(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isShowMessage]);

    const handleShowMessage = () => {
        let ticket = handleFormTicket();
        let msg = '';
        if (ticket.username == undefined) {
            msg = 'Укажите имя пользователя'
        } 
        if (ticket.phone == undefined) {
            msg = 'Укажите номер телефона пользователя'
        }
        if (ticket.user_app_login == undefined) {
            msg = 'Укажите логин пользователя в приложении'
        } 
        if (ticket.station_num == undefined) {
            msg = 'Укажите номер станции'
        } 
        if (ticket.category_id == undefined) {
            msg = 'Пожалуйста, выберете категорию'
        }
        if (ticket.comment == undefined || ticket.comment == "") {
            msg = 'Опишите проблему в поле Комментарий'
        } 
        if (ticket.comment && ticket.comment.length < 50) {
            msg = 'Пожалуйста, опишите проблему подробнее (>50 символов)'
        } 
        console.log(ticket)
        console.log(msg)
        setIsShowMessage(msg);
        
        if (msg == '') {
            postApi('ticket', ticket)
            .then(() => {
                console.log('Заявка успешно сформирована');
                setIsShowMessage('Заявка успешно сформирована')
            })
            .catch(error => {
                console.error('Ошибка при добавлении данных:', error);
            });
        }
    };

    const handleStart = () => {
        setIsStarted(true);
    };

    const handleSlideChange = (swiper) => {
        setIsLastSlide(swiper.isEnd);
    };

    const renderButton = () => {
        if (isLastSlide) {
            return (
                <div className={styles.swiperButtonsLast}>
                    {isShowMessage ?
                        <h2>{isShowMessage == '' ? 'Заявка успешно сформирована' : isShowMessage}</h2>
                        :
                        <Button
                            text={'Сформировать заявку'}
                            width={343}
                            primary={true}
                            onClick={() => {
                                handleFormTicket();
                                handleShowMessage();
                            }}
                        />
                    }

                    <div className={styles.coloredButton}>
                        <Button
                            text={'Назад'}
                            width={343}
                            onClick={() => {
                                swiper.slideTo(0);
                                setIsStarted(false);
                            }}
                        />
                    </div>
                </div>
            );
        } else if (isStarted) {
            return (
                <div className={styles.swiperButtons}>
                    <Button
                        text={'Назад'}
                        width={125}
                        onClick={() => {
                            swiper.slidePrev();
                        }}
                    />
                    <div className={styles.primary}>
                        <Button
                            text={'Проблема решена'}
                            width={343}
                            primary={true}
                            onClick={() => {
                                swiper.slideTo(0);
                                setIsStarted(false);
                            }}
                        />
                    </div>
                    <div className={styles.coloredButton}>
                        <Button
                            text={'К заявке'}
                            width={125}
                            onClick={() => {
                                swiper.slideNext();
                            }}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.swiperButtons}>
                    <Button
                        text={'Начать'}
                        width={343}
                        primary={true}
                        onClick={() => {
                            handleStart();
                            swiper.slideNext();
                        }}
                    />
                </div>
            );
        }
    };

    return (
        <div className={styles.operator}>
            <div className={styles.content}>
                {isLastSlide ?
                    (<>
                        <span className={styles.ticketHead}>Заполните все поля для формирования завяки в диспетчерский центр</span>
                        <span>Пожалуйста, будьте предельно внимательны</span>
                    </>) :
                    (<>
                        <span className={styles.head}>Новое обращение</span>
                        <span>Пожалуйста, убедитесь в корректности действий пользователя</span>
                    </>)
                }
                <SwiperHelpdesk {...{data, setSwiper, handleSlideChange}}/>
                {renderButton()}
            </div>
        </div>
    );
};

export default HelpdeskOperator;
