import React, { useContext, useEffect, useState } from 'react';

import styles from './Helpdesk.module.scss';
import Input from '../../../components/Input/Input';
import InputRadio from '../../../components/InputRadio/InputRadio';
import { getApi } from '../../../Api';
import { MyContext } from '../../../store';

const Ticket = () => {
    const [data, setData] = useState([]);
    const {
        handleChangeUsername,
        handleChangePhone,
        handleChangeUserAppLogin,
        handleChangeStationNum,
        handleChangeCategoryId,
        handleChangeComment
    } = useContext(MyContext);

    useEffect(() => {
        getApi('category/active')
            .then(info => {
                setData(info);
            });
    }, []);

    return (
        <div className={styles.ticket}>
            <div>
                <div className={styles.ticketInput}>
                    <Input
                        width={395}
                        placeholder={'Фамилия Имя Отчество'}
                        onChange={handleChangeUsername}
                    />
                    <Input
                        width={306.5}
                        placeholder={'Номер телефона'}
                        onChange={handleChangePhone}
                    />
                </div>
                <div className={styles.ticketInput}>
                    <Input
                        width={306.5}
                        placeholder={'Имя пользователя в приложении'}
                        onChange={handleChangeUserAppLogin}
                    />
                    <Input
                        width={395}
                        placeholder={'Номер станции (6 цифр на левой/лицевой стороне)'}
                        onChange={handleChangeStationNum}
                    />
                </div>
            </div>
            <div className={styles.ticketInputRadio}>
                <div>
                    <ul>
                        {
                            data.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <InputRadio
                                            text={item.name}
                                            value={item.id}
                                            onChange={handleChangeCategoryId}
                                        />
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className={styles.ticketTextarea}>
                <span>Комментарий</span>
                <textarea
                    placeholder={'Кратко опишите проблему пользователя. Внимание! Не менее 50 знаков!'}
                    onChange={handleChangeComment}
                ></textarea>
            </div>
        </div>
    );
};

export default Ticket;
