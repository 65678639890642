import React from 'react';
import { HandySvg } from 'handy-svg';
import { useNavigate } from 'react-router-dom';

import styles from './Header.module.scss';
import logo from '../../assets/vectorHeader/Logo.svg';
import Menu from './Menu';

const Header = () =>{
    const user = JSON.parse(localStorage.getItem('user'));
    useNavigate();

    const handleClick = () => {
        localStorage.clear();
    };

    return (
        <header>
            <div className={styles.logo}>
                <HandySvg
                    src={logo}
                    width='33'
                    height='20'
                />
                <span>Malanka Automated Control Center</span>
            </div>
            {
                user ? <Menu handleClick={handleClick} name={user.username}/> : null
            }
        </header>
    );
};

export default Header;
