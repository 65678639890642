import React, { useEffect, useState } from 'react';
import { HandySvg } from 'handy-svg';
import classNames from 'classnames';

import Input from '../../../components/Input/Input';
import { getApi, postApi, putApi } from '../../../Api';
import style from './Categories.module.scss';
import Button from '../../../components/Button/Button';
import plus from '../../../assets/vectorCategories/Plus.svg';

const Categories = () => {
    const [data, setData] = useState([]);
    const [name, setName] = useState(undefined);
    const [isActive, setIsActive] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        getApi('category')
            .then(info => {
                setData(info);
            });
    }, []);

    const handleNameChange = (event) => {
        setIsDisabled(false);
        setName(event.target.value);
    };

    const handleCheckedChange = (event) => {
        setIsDisabled(false);
        setIsActive(!event.target.checked);
    };

    const handleSave = () => {
        const putRequests = [];

        data.forEach(item => {
            const checkboxElement = document.getElementById(`checkbox-${item.id}`);
            const inputElement = document.getElementById(`input-${item.id}`);

            const category = {
                name: inputElement.value,
                is_active: checkboxElement.checked
            };

            putRequests.push(putApi(`category/${item.id}`, category));
        });

        Promise.all(putRequests)
            .then(responses => {
                console.log('Данные успешно обновлены');
                setData(responses);
            })
            .catch(error => {
                console.error('Ошибка при сохранении данных:', error);
            });
    };

    const handleAdd = () => {
        const category = {
            name: name,
            is_active: isActive
        };

        postApi('category', category)
            .then(response => {
                console.log('Данные успешно добавлены');
                setData([...data, response]);
            })
            .catch(error => {
                console.error('Ошибка при добавлении данных:', error);
            });
    };

    return (
        <div className={style.categories}>
            <span>Изменить карточку диспетчера колл-центра</span>
            {
                data.map((item, index) => {
                    return (
                        <div className={style.cards} key={index}>
                            <span className={style.count}>{index + 1}</span>
                            <Input
                                id={`input-${item.id}`}
                                defaultValue={item.name}
                                width={250}
                                height={42}
                                onChange={handleNameChange}
                            />
                            <label className={style.container}>
                                <input
                                    className={style.customCheckbox}
                                    type={'checkbox'}
                                    id={`checkbox-${item.id}`}
                                    defaultChecked={item.is_active}
                                    onChange={handleCheckedChange}
                                />
                                <span className={style.checkbox}></span>
                            </label>
                        </div>
                    );
                })
            }
            <div className={classNames(style.cards, style.new)}>
                <span className={style.count}>{data.length + 1}</span>
                <Input
                    placeholder={'Новая категория'}
                    width={250}
                    height={42}
                    onChange={handleNameChange}
                />
                <button
                    onClick={handleAdd}
                    className={style.plus}
                    type='submit'
                    disabled={isDisabled}
                >
                    <HandySvg src={plus} width='11' height='11'/>
                </button>
            </div>
            <Button
                text={'Сохранить'}
                primary={true}
                width={343}
                onClick={handleSave}
                disabled={isDisabled}
                type='submit'
            />
        </div>
    );
};

export default Categories;
