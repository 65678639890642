import React, { useEffect, useState } from 'react';

import styles from './Main.module.scss';
import { getApi } from '../../../Api';

const MainOperator = ({Outlet}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getApi('ticket/info')
            .then(info => {
                setData(info);
            });
    }, []);

    return (
        <div className={styles.mainOperator}>
            <ul className={styles.mainInfo}>
                <li><span>{data.open}</span>В обработке</li>
                <li><span>{data.closed}</span>Завершено</li>
            </ul>
            <Outlet/>
        </div>
    );
};

export default MainOperator;
