import { HandySvg } from 'handy-svg';
import React, { useState } from 'react';

import style from './Helpdesk.module.scss';
import Card from '../../../components/Card/Card';
import leftArrow from '../../../assets/vectorSolutions/LeftArrow.svg';
import Input from '../../../components/Input/Input';
import rightArrow from '../../../assets/vectorSolutions/RightArrow.svg';
import remove from '../../../assets/vectorSolutions/Remove.svg';
import Button from '../../../components/Button/Button';
import add from '../../../assets/vectorSolutions/Add.svg';
import { deleteApi, patchApi, postApi } from '../../../Api';

const HelpdeskAdministrator = ({data, setData}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [header, setHeader] = useState(undefined);
    const [content, setContent] = useState(undefined);
    const [queue, setQueue] = useState(undefined);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleNextClick = () => {
        setCurrentIndex((nextIndex) => (nextIndex + 1) % data.length);
    };

    const handlePreviousClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1) % data.length);
    };

    const handleHeaderChange = (event) => {
        setIsDisabled(false);
        setHeader(event.target.value);
    };

    const handleContentChange = (event) => {
        setIsDisabled(false);
        setContent(event.target.value);
    };

    const handleQueueChange = (event) => {
        setIsDisabled(false);
        setQueue(event.target.value);
    };

    const handleSave = () => {
        const helpdesk = {
            header: header,
            content: content,
            queue: queue
        };
        const currentHelpdesk = data.find(helpdesk => helpdesk.queue === currentIndex + 1);

        if (currentHelpdesk.isNew) {
            postApi('helpdesk', helpdesk)
                .then(response => {
                    console.log('Данные успешно добавлены');
                    setData([
                        ...data.slice(0, currentIndex),
                        response,
                        ...data.slice(currentIndex + 1)
                    ]);
                });
        } else {
            if ((helpdesk.queue || currentHelpdesk.queue) <= data.length) {
                patchApi(`helpdesk/${currentHelpdesk.id}`, helpdesk)
                    .then(response => {
                        console.log('Данные успешно обновлены');
                        setData([
                            ...data.slice(0, currentIndex),
                            response,
                            ...data.slice(currentIndex + 1)
                        ]);
                        setCurrentIndex(response.queue - 1);
                    });
            } else {
                console.log('Невозможно изменить');
            }
        }
    };

    const handleAdd = () => {
        const helpdesk = {
            header: undefined,
            content: undefined,
            queue: data.length + 1,
            isNew: true
        };
        setCurrentIndex(data.length);
        setQueue(data.length + 1);
        setData([...data, helpdesk]);
    };

    const handleDelete = () => {
        const currentHelpdesk = data.find(helpdesk => helpdesk.queue === currentIndex + 1);

        deleteApi(`helpdesk/${currentHelpdesk.id}`)
            .then(() => {
                console.log('Данные успешно удалены');
            });

        setData([...data.slice(0, currentIndex), ...data.slice(currentIndex + 1)]
            .map(helpdesk => {
                if (helpdesk.queue > currentHelpdesk.queue) {
                    --helpdesk.queue;
                }
                return helpdesk;
            }));

        setCurrentIndex(0);
    };

    return (
        <div className={style.administrator}>
            <span>Изменить карточку диспетчера колл-центра</span>
            {
                data.map((items, index) => {
                    return (
                        index === currentIndex &&
                        <div className={style.card} key={index}>
                            <Card content={items.content} onChange={handleContentChange}>
                                {
                                    (
                                        <div className={style.header}>
                                            <button onClick={handlePreviousClick} disabled={currentIndex === 0}>
                                                <HandySvg
                                                    src={leftArrow}
                                                    width='10'
                                                    height='17.78'
                                                />
                                            </button>
                                            <Input
                                                defaultValue={items.header}
                                                width={'500'}
                                                height={'28.5'}
                                                onChange={handleHeaderChange}
                                            />
                                            <button onClick={handleNextClick}
                                                    disabled={currentIndex === data.length - 1}>
                                                <HandySvg
                                                    src={rightArrow}
                                                    width='10'
                                                    height='17.78'
                                                />
                                            </button>
                                        </div>
                                    )
                                }
                            </Card>
                            <div className={style.number}>
                                <Input defaultValue={items.queue} onChange={handleQueueChange}/>
                                <span>из {data.length}</span>
                            </div>
                        </div>
                    );
                })
            }
            <div className={style.buttons}>
                <button className={style.circleButton} onClick={handleDelete}>
                    <HandySvg
                        src={remove}
                        width='15.32'
                        height='18'
                    />
                </button>
                <div className={style.midButton}>
                    <Button
                        text={'Сохранить'}
                        primary={true}
                        width={343}
                        onClick={handleSave}
                        disabled={isDisabled}
                    />
                </div>
                <button className={style.circleButton} onClick={handleAdd}>
                    <HandySvg
                        src={add}
                        width='15'
                        height='15'
                    />
                </button>
            </div>
        </div>
    );
};

export default HelpdeskAdministrator;
