import React from 'react';

import styles from './Main.module.scss';
import MenuMain from '../../../components/MenuMain/MenuMain';

const menuItems = [
    {
        title: 'Карточки',
        url: 'helpdesk'
    },
    {
        title: 'Категории',
        url: 'categories'
    },
    {
        title: 'Пользователи',
        url: 'users'
    },
    {
        title: 'Статистика',
        url: 'statistics'
    },
    {
        title: 'История',
        url: 'history'
    }
];

const MainAdministrator = ({Outlet}) => {
    return (
        <div className={styles.main}>
            <ul className={styles}>
                <MenuMain {...{menuItems}}/>
            </ul>
            <Outlet/>
        </div>
    );
};

export default MainAdministrator;