import styles from './Helpdesk.module.scss';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { getApi, postApi } from '../../../Api';
import Button from '../../../components/Button/Button';

const HelpdeskDispatcher = () => {
    const [data, setData] = useState([]);
    const [dataId, setDataId] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [activeTicketId, setActiveTicketId] = useState(null);
    const [isShowMessage, setIsShowMessage] = useState(false);

    useEffect(() => {
        if (isShowMessage) {
            const timer = setTimeout(() => {
                setIsShowMessage(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isShowMessage]);

    useEffect(() => {
        getApi('ticket')
            .then(info => {
                setData(info);
            });
    }, []);

    const handleTabClick = (id) => {
        setIsDisabled(false);
        setActiveTicketId(id);

        getApi(`ticket/${id}`)
            .then(info => {
                setDataId(info);
            });
    };

    const handleClose = () => {
        const ticket = {
            id: dataId.id,
            report: dataId.report
        };

        postApi('ticket/close', ticket)
            .then(() => {
                getApi('ticket')
                    .then(info => {
                        setData(info);
                    });
                setIsShowMessage(true);
                setTimeout(() => setIsDisabled(true), 3000);
                console.log('Тикет успешно закрыт');
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div className={styles.dispatcher}>
            <ul className={styles.request}>
                {data.map((ticket, index) => {
                    const date = new Date(ticket.date_open).toLocaleString('ru-RU');

                    return (
                        <li
                            onClick={() => handleTabClick(ticket.id)}
                            className={classNames(ticket.id === activeTicketId ? styles.active : '')}
                            key={index}
                        >
                            <span>{date}</span>
                            <h3>Заявка №{ticket.id}</h3>
                            {ticket.category_name}
                        </li>
                    );
                })
                }
            </ul>
            {!isDisabled ?
                <div className={styles.ticket}>
                    <div className={styles.ticketInfo}>
                        <div className={styles.ticketId}>
                            <h2>Заявка №{dataId.id}</h2>
                            <span>{dataId.category_name}</span>
                            <span>Номер станции {dataId.station_num}</span>
                        </div>
                        <div className={styles.ticketPerson}>
                            <h3>{dataId.username}</h3>
                            <span>{dataId.user_app_login}</span>
                            <span>{dataId.phone}</span>
                        </div>
                        <div className={styles.ticketComment}>
                            <h3>Комментарий</h3>
                            <div>{dataId.comment}</div>
                        </div>
                    </div>
                    {data.length !== 0 ?
                        isShowMessage ?
                            <h2>Заявка успешно закрыта</h2>
                            :
                            <Button
                                width={343}
                                text={'Выполнено'}
                                primary={true}
                                onClick={handleClose}
                            />
                        :
                        <h2>Нет открытых заявок</h2>
                    }
                </div>
                :
                <></>
            }
        </div>
    );
};

export default HelpdeskDispatcher;
