import { useEffect, useState } from 'react';
import { HandySvg } from 'handy-svg';

import { getApi, postApi,deleteApi } from '../../../Api';
import style from './Users.module.scss';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import remove from '../../../assets/vectorUsers/Delete.svg';
import InputRadio from '../../../components/InputRadio/InputRadio';

const Users = () => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [pass, setPass] = useState();

    const changeValue = e => {
        setValue(e.target.value);
    };
    const changeEmail = e => {
        setEmail(e.target.value);
    };
    const changeName = e => {
        setName(e.target.value);
    };

    useEffect(() => {
        getApi('user')
            .then(info => {
                setData(info);
            });
    }, []);

    const addUser = () => {
        const user = {
            name: name,
            email: email,
            role_id: value
        }
        postApi('user', user)
            .then(response => {
                console.log(JSON.stringify(response))
                setPass(response.password)
            })
            .catch(e => {
                console.log(e);
            });
        console.log(email)
    }

    const deleteUser = (id) => {
        deleteApi(`user/${id}`, id)
            .then(response => {
                console.log(JSON.stringify(response))
            })
            .catch(e => {
                console.log(e);
            });
        console.log(id)
    }

    return (
        <div className={style.users}>
            <span>Добавить пользователя</span>
            <div>
                <ul>
                    <li>
                        <InputRadio text={'Оператор техподдержки'} value={3} onChange={changeValue} />
                    </li>
                    <li>
                        <InputRadio text={'Оператор Malanka'} value={2} onChange={changeValue} />
                    </li>
                    <li>
                        <InputRadio text={'Администратор'} value={1} onChange={changeValue} />
                    </li>
                </ul>
                <div className={style.inputs}>
                    <Input placeholder={'Фамилия Имя Отчество'} width={'493'} height={'44'} onChange={changeName} />
                    <Input placeholder={'Email'} width={'493'} height={'44'} onChange={changeEmail} />
                    {pass ? 
                        <div>
                            Ваш пароль: {pass}
                        </div> 
                    : null}
                    <Button
                        text={'Добавить'}
                        width={'200'}
                        primary={true}
                        onClick={() => addUser()}
                    />
                </div>
            </div>
            <span>Администрирование пользователей системы</span>
            <div>
                {
                    data.map((users, index) => {
                        return (
                            <div className={style.administrating} key={index}>
                                <div className={style.table}>
                                    <label>{users.name}</label>
                                    <label>{users.email}</label>
                                    <label>{users.role}</label>
                                </div>
                                <button 
                                    className={style.circleButton}
                                    onClick={() => deleteUser(users.id)}
                                >
                                    <HandySvg
                                        src={remove}
                                        width='15.32'
                                        height='18'
                                    />
                                </button>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Users;
