import styles from './InputRadio.module.scss';

const InputRadio = ({text, value, onChange}) => {
    return (
        <>
            <input
                name={'radio'}
                type={'radio'}
                value={value}
                onChange={onChange}
                className={styles}
            />{text}
        </>
    );
};

export default InputRadio;
