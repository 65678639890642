import style from './Card.module.scss';

const Card = ({children, content, onChange}) => {
    return (
        <div>
            {children}
            <div className={style.content}>
                <textarea defaultValue={content} onChange={onChange}/>
            </div>
        </div>
    );
}

export default Card;
