import React, { useEffect, useState } from 'react';

import { getApi } from '../../../Api';
import HelpdeskAdministrator from './HelpdeskAdministrator';
import HelpdeskOperator from './HelpdeskOperator';
import HelpdeskDispatcher from './HelpdeskDispatcher';

const Helpdesk = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const [data, setData] = useState([]);

    useEffect(() => {
        getApi('helpdesk')
            .then(info => {
                setData(info);
            });
    }, []);

    switch (user.role) {
        case 'Администратор':
            return <HelpdeskAdministrator {...{data, setData}} />;
        case 'Оператор Malanka':
            return <HelpdeskOperator {...{data}}/>;
        case 'Оператор техподдержки':
            return <HelpdeskDispatcher/>
        default:
            return <></>;
    }
};

export default Helpdesk;
