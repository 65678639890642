const SupportPage = () => {
    return (
        <div>
            SupportPage
        </div>
    );
}

export default SupportPage;

