import { createContext, useState } from 'react';
import { postApi } from '../Api';

const MyContext = createContext({});

const Provider = ({children}) => {
    const [username, setUsername] = useState();
    const [phone, setPhone] = useState();
    const [userAppLogin, setUserAppLogin] = useState();
    const [stationNum, setStationNum] = useState();
    const [categoryId, setCategoryId] = useState();
    const [comment, setComment] = useState();

    const handleChangeUsername = e => {
        setUsername(e.target.value);
    };
    const handleChangePhone = e => {
        setPhone(e.target.value);
    };
    const handleChangeUserAppLogin = e => {
        setUserAppLogin(e.target.value);
    };
    const handleChangeStationNum = e => {
        setStationNum(e.target.value);
    };
    const handleChangeCategoryId = e => {
        setCategoryId(e.target.value);
    };
    const handleChangeComment = e => {
        setComment(e.target.value);
    };

    const handleFormTicket = () => {
        const ticket = {
            'username': username,
            'phone': phone,
            'user_app_login': userAppLogin,
            'station_num': stationNum,
            'category_id': categoryId,
            'comment': comment
        };
        return ticket
        // postApi('ticket', ticket)
        //     .then(() => {
        //         console.log('Данные успешно добавлены');
        //     })
        //     .catch(error => {
        //         console.error('Ошибка при добавлении данных:', error);
        //     });
    };

    return (
        <MyContext.Provider value={{
            handleChangeUsername,
            handleChangePhone,
            handleChangeUserAppLogin,
            handleChangeStationNum,
            handleChangeCategoryId,
            handleChangeComment,
            handleFormTicket
        }}>
            {children}
        </MyContext.Provider>
    );
};

export { MyContext };

export default Provider;
