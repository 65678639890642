import React from 'react';
import classNames from 'classnames';

import style from './Button.module.scss';

const Button = ({text, width, height = 46, onClick, primary, disabled}) => {
    return (
        <button
            style={{width: `${width}px`, height: `${height}px`}}
            className={classNames(style.customButton, primary ? style.buttonPrimary : style.button)}
            type='submit'
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default Button;
