import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import Main from './pages/MainPage/Main/Main';
import SupportPage from './pages/SupportPage';
import UserPage from './pages/UserPage';
import AuthPage from './pages/AuthPage/AuthPage';
import RequestPage from './pages/RequestPage';
import Helpdesk from './pages/MainPage/Helpdesk/Helpdesk';
import Categories from './pages/MainPage/Categories/Categories';
import Users from './pages/MainPage/Users/Users';
import Statistics from './pages/MainPage/Statistics';
import History from './pages/MainPage/History';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

const Routing = () => {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path={'/'} element={<App/>}>
                    <Route path={'/main'} element={<Main/>}>
                        <Route path={'helpdesk'} element={<Helpdesk/>}/>
                        <Route path={'categories'} element={<Categories/>}/>
                        <Route path={'users'} element={<Users/>}/>
                        <Route path={'statistics'} element={<Statistics/>}/>
                        <Route path={'history'} element={<History/>}/>
                    </Route>
                    <Route path={'/support'} element={<SupportPage/>}/>
                    <Route path={'/user'} element={<UserPage/>}/>
                    <Route path={'/request'} element={<RequestPage/>}/>
                </Route>
                <Route path={'/auth'} element={<AuthPage/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
};

export default Routing;
