import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';

import styles from './Helpdesk.module.scss';
import Ticket from './Ticket';

const SwiperHelpdesk = ({data, setSwiper, handleSlideChange}) => {
    return (
        <div className={styles.swiper}>
            <Swiper
                pagination={{type: 'fraction'}}
                modules={[Pagination]}
                onSlideChange={handleSlideChange}
                onSwiper={(swiper) => {
                    setSwiper(swiper);
                }}
            >
                {
                    data.map((items, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className={styles.swiperSlide}>
                                    <span>{items.header}</span>
                                    <div className={styles.contentText}>{items.content}</div>
                                </div>
                            </SwiperSlide>
                        );
                    })
                }
                <SwiperSlide key={data.length}>
                    <Ticket/>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default SwiperHelpdesk;
