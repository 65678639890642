export async function postAuth(path, info) {
    const url = `http://malankahelp.by/api/${path}/`;

    return (
        fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                return response.json();
            })
    );
}


export async function getApi(path) {
    const url = `http://malankahelp.by/api/${path}/`;

    return (
        await fetch(`${url}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Basic ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                return response.json();
            })
    );
}

export async function postApi(path, info) {
    const url = `http://malankahelp.by/api/${path}/`;

    return (
        fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + localStorage.getItem('token')
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                return response.json();
            })
    );
}

export async function patchApi(path, info) {
    const url = `http://malankahelp.by/api/${path}/`;

    return (
        fetch(`${url}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + localStorage.getItem('token')
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                return response.json();
            })
    );
}

export async function putApi(path, info) {
    const url = `http://malankahelp.by/api/${path}/`;

    return (
        fetch(`${url}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + localStorage.getItem('token')
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                return response.json();
            })
    );
}

export async function deleteApi(path) {
    const url = `http://malankahelp.by/api/${path}/`;

    return (
        fetch(`${url}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + localStorage.getItem('token')
            }
        })
    );
}
