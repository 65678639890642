import React from 'react';
import { Outlet } from 'react-router-dom';

import MainAdministrator from './MainAdministrator';
import MainOperator from './MainOperator';
import MainDispatcher from './MainDispatcher';

const Main = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    switch (user.role) {
        case 'Администратор':
            return <MainAdministrator Outlet={Outlet}/>;
        case 'Оператор Malanka':
            return <MainOperator Outlet={Outlet}/>;
        case 'Оператор техподдержки':
            return <MainDispatcher Outlet={Outlet}/>
        default:
            return <></>;
    }
};

export default Main;
