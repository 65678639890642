import { NavLink } from 'react-router-dom';

import styles from './MenuMain.module.scss';
import classNames from 'classnames';

const MenuMain = ({menuItems}) => {
    const onClassName = ({isActive}) => isActive
        ? classNames(styles.activeLink, styles.link)
        : styles.link;

    return (
        menuItems.map((menu, index) => (
            <li key={index}>
                <NavLink
                    to={`/main/${menu.url}`}
                    className={onClassName}
                >
                    {menu.title}
                </NavLink>
            </li>
        ))
    );
};

export default MenuMain;
