import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Auth.module.scss';
import { postApi, postAuth } from '../../Api';

const AuthPage = () => {
    const navigate = useNavigate();

    const HandleAuth = (values) => {
        postAuth('user/auth', values)
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response));
                localStorage.setItem('token', btoa(`${values.email}:${values.password}`));
                navigate('/main/helpdesk');
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div className={styles.signIn}>
            <span>Пожалуйста, войдите</span>
            <>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={async (values, actions) => {
                        await new Promise(resolve => setTimeout(resolve, 500));
                        actions.resetForm({
                            values: {
                                email: '',
                                password: ''
                            }
                        });
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .required('Required'),
                        password: Yup.string()
                            .required('Required')
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            dirty,
                            isValid
                        } = props;
                        return (
                            <Form onSubmit={handleSubmit}>
                                <input
                                    id='email'
                                    placeholder='Email'
                                    type='email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classNames('', {[styles.inputError]: errors.email && touched.email})}
                                />
                                <input
                                    id='password'
                                    placeholder='Пароль'
                                    type='password'
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classNames('', {[styles.inputError]: errors.password && touched.password})}
                                />
                                {
                                    (errors.email && touched.email) || (errors.password && touched.password) ?
                                        <button
                                            type='submit'
                                            disabled={isSubmitting}
                                            className={classNames(styles.signInButton, styles.signInButtonError)}
                                        >
                                            Заполните форму корректно
                                        </button>
                                        :
                                        <button
                                            type='submit'
                                            disabled={!dirty || !isValid}
                                            onClick={() => HandleAuth(values)}
                                            className={styles.signInButton}
                                        >
                                            Войти
                                        </button>
                                }
                            </Form>
                        );
                    }}
                </Formik>
            </>
        </div>
    );
};

export default AuthPage;
