

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import Routing from './Routing';
import Provider from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider>
            <Routing/>
        </Provider>
    </React.StrictMode>
);

// reportWebVitals();
