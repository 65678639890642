import './Footer.module.scss';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer>
            {`© ${year} Маланка. Все права защищены`}
        </footer>
    );
}

export default Footer;
