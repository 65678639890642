import React, { useContext, useEffect, useState } from 'react';
import { getApi } from '../../Api';

const History = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        getApi('ticket')
            .then(info => {
                setData(info);
            });
    }, []);

    return (
        <div>
            {data ? data.map(item => (
                <div>
                    <div>{item.comment}</div>
                    <div>{item.date_open}</div>
                    <div>{item.date_close}</div>
                </div>
                
            )) : null}
        </div>
    );
}

export default History;
